<template>
  <div>
    <div class="error">
      <div class="callout alert" v-if="errors.length > 0">
        Bitte korrigieren Sie die rot markierten Felder.
      </div>
    </div>
    <div class="customer">
      <div class="gender">
        <label for="gender" :class="checkError('gender')">Geschlecht</label>
        <select id="gender" name="gender" v-model="customer.gender">
          <option value=""></option>
          <option value="diverse">Divers</option>
          <option value="male">Männlich</option>
          <option value="female">Weiblich</option>
        </select>
      </div>
      <div class="firstname">
        <label for="firstname" :class="checkError('firstname')">Vorname</label>
        <input
          type="text"
          id="firstname"
          name="firstname"
          v-model="customer.firstname"
        />
      </div>
      <div class="lastname">
        <label for="lastname" :class="checkError('lastname')">Nachname</label>
        <input
          type="text"
          id="lastname"
          name="lastname"
          v-model="customer.lastname"
        />
      </div>
      <div class="street">
        <label for="street" :class="checkError('street')">Straße</label>
        <input
          type="text"
          id="street"
          name="street"
          v-model="customer.street"
        />
      </div>
      <div class="streetNumber">
        <label for="streetNumber" :class="checkError('streetNumber')"
          >Hausnummer</label
        >
        <input
          type="text"
          id="streetNumber"
          name="streetNumber"
          v-model="customer.streetNumber"
          maxlength="16"
        />
      </div>
      <div class="postal">
        <label for="postal" :class="checkError('postal')">Postleitzahl</label>
        <input
          type="text"
          id="postal"
          name="postal"
          v-model="customer.postal"
          minlength="5"
          maxlength="5"
        />
      </div>
      <div class="city">
        <label for="city" :class="checkError('city')">Ort</label>
        <input type="text" id="city" name="city" v-model="customer.city" />
      </div>
      <div class="email">
        <label for="email" :class="checkError('email')">E-Mail</label>
        <input type="text" id="email" name="email" v-model="customer.email" />
      </div>
      <div class="phone">
        <label for="phone" :class="checkError('phone')">Telefon</label>
        <input type="text" id="phone" name="phone" v-model="customer.phone" />
      </div>
    </div>

    <div class="agb">
      <div class="widget">
        <input
          type="checkbox"
          id="agb"
          name="agb"
          v-model="customer.agb"
          value="true"
          :class="checkError('agb')"
        />
        <label for="agb" :class="checkError('agb')"
          ><a :href="settings.linkAgb" target="_blank">AGB</a> habe ich zur
          Kenntnis genommen und ich bin mit diesen einverstanden.</label
        >
      </div>
    </div>

    <div class="dataPrivacy">
      <div class="widget">
        <input
          type="checkbox"
          id="dataPrivacy"
          name="dataPrivacy"
          v-model="customer.dataPrivacy"
          value="true"
          :class="checkError('dataPrivacy')"
        />
        <label for="dataPrivacy" :class="checkError('dataPrivacy')"
          ><a :href="settings.linkDataPrivacy" target="_blank"
            >Datenschutzinformation</a
          >
          habe ich zur Kenntnis genommen</label
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Customer",
  props: ["customer", "settings"],
  data() {
    return {
      customerValue: {},
      errors: []
    };
  },
  computed: {
    customerProxy: {
      set: function(value) {
        this.customerValue = value;
        this.$emit("update:customer", this.customerValue);
      },
      get: function() {
        return this.customerValue;
      }
    }
  },
  methods: {
    checkForm() {
      let errors = [];
      if (this.customer.gender === undefined || this.customer.gender === "")
        errors.push("gender");
      if (
        this.customer.firstname === undefined ||
        this.customer.firstname === ""
      )
        errors.push("firstname");
      if (this.customer.lastname === undefined || this.customer.lastname === "")
        errors.push("lastname");
      if (this.customer.street === undefined || this.customer.street === "")
        errors.push("street");
      if (
        this.customer.streetNumber === undefined ||
        this.customer.streetNumber === ""
      )
        errors.push("streetNumber");
      if (
        this.customer.postal === undefined ||
        this.customer.postal === "" ||
        !/[0-9]{5}/.test(this.customer.postal)
      )
        errors.push("postal");
      if (this.customer.city === undefined || this.customer.city === "")
        errors.push("city");
      if (
        this.customer.email === undefined ||
        this.customer.email === "" ||
        !/(.+)@(.+){2,}\.(.+){2,}/.test(this.customer.email)
      )
        errors.push("email");
      if (this.customer.phone === undefined || this.customer.phone === "")
        errors.push("phone");
      if (this.customer.agb === undefined || this.customer.agb === "")
        errors.push("agb");
      if (
        this.customer.dataPrivacy === undefined ||
        this.customer.dataPrivacy === ""
      )
        errors.push("dataPrivacy");

      this.errors = errors;
      return this.errors.length === 0;
    },
    checkError(field) {
      return this.errors.indexOf(field) !== -1 ? "error" : "";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.customer {
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 0.25rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    "gender firstname firstname lastname lastname ."
    "street street streetNumber postal city city"
    "email email phone phone . .";

  @media (max-width: 640px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(7, 1fr);
    grid-template-areas:
      "gender gender ."
      "firstname firstname firstname"
      "lastname lastname lastname"
      "street street streetNumber"
      "postal city city"
      "email email email"
      "phone phone phone";
  }

  .gender {
    grid-area: gender;
  }

  .firstname {
    grid-area: firstname;
  }

  .lastname {
    grid-area: lastname;
  }

  .street {
    grid-area: street;
  }

  .streetNumber {
    grid-area: streetNumber;
  }

  .postal {
    grid-area: postal;
  }

  .city {
    grid-area: city;
  }

  .email {
    grid-area: email;
  }

  .phone {
    grid-area: phone;
  }
}

.agb,
.dataPrivacy {
  margin-bottom: 2rem;
  .widget {
    display: flex;
  }
}
</style>
