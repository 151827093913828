<template>
  <div class="step" :data-step="number" :class="[
      step > number ? 'completed' : '',
      step == number ? 'active' : ''
  ]">
    <span class="badge">{{ number }}</span>
    {{ name }}
  </div>
</template>

<script>
export default {
  name: 'StepperHeader',
  props: ['number', 'step', 'name']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
