<template>
  <div class="waiting-list" ref="top">
    <div class="grid-container">
      <h2>Warteliste</h2>
      <p>
        Liebe Eltern. Liebe Teilnehmer*innen,<br />
        <br />
        vielen Dank für Ihr Interesse an unseren Clubs.<br />
        Bitte tragen Sie für ein schnelles Ergebnis alle möglichen Zeiten und
        Standorte ein. Sie erhalten nach Ihren Präferenzen passende
        Clubangebote. Nach Erhalt können Sie diese für eine verbindliche Buchung
        bestätigen. !Ein Eintrag in die Warteliste ist keine Buchung!<br />
        <br />
        Wir können keine Prognosen über Wartezeiten auf diesem Wege ausgeben.
        Erfahrungsgemäß beträgt die Wartezeit durchschnittlich 2 bis 3 Monate.
      </p>
    </div>

    <div class="form">
      <div class="stepper">
        <div class="header" ref="stepperHeader">
          <StepperHeader
            name="Präferenzen"
            number="1"
            :step="step"
          ></StepperHeader>
          <div class="divider"></div>
          <StepperHeader
            name="Persönliche Daten"
            number="2"
            :step="step"
          ></StepperHeader>
          <div class="divider"></div>
          <StepperHeader
            name="Teilnehmer"
            number="3"
            :step="step"
          ></StepperHeader>
          <div class="divider"></div>
          <StepperHeader
            name="Zusammenfassung"
            number="4"
            :step="step"
          ></StepperHeader>
        </div>
        <div class="content" ref="contentTop">
          <div class="step" v-if="step === 1">
            <Preferences
              :baths="baths"
              :categories="clubCategories"
              :preferences.sync="preferences"
              ref="preferences"
            ></Preferences>
          </div>
          <div class="step" v-else-if="step === 2">
            <Customer
              :customer.sync="customer"
              :settings="settings"
              ref="customer"
            ></Customer>
          </div>
          <div class="step" v-else-if="step === 3">
            <Participant
              :participant.sync="participant"
              ref="participant"
            ></Participant>
          </div>
          <div class="step" v-else-if="step === 4">
            <WaitingListSummary
              v-on:changeStep="changeStep"
              :customer="customer"
              :participant="participant"
              :preferences="preferences"
              ref="summary"
            ></WaitingListSummary>
          </div>
          <div class="step" v-else-if="step === 5">
            <div class="callout success">
              <h5>Vielen Dank für Ihren Eintrag</h5>
              <p>
                Bitte fügen Sie unsere E-Mailadresse {{ settings.email }} in Ihr
                Adressbuch hinzu. So gewährleisten Sie ein Zugang aller
                relevanten Mails.
              </p>
              <a :href="settings.homepage" target="_blank">{{
                settings.company
              }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button
          class="button"
          @click="relocateToCourseList"
          v-if="step <= 1 || step >= 5"
        >
          Zurück zur Übersicht
        </button>
        <button class="button" @click="previous" v-else>Zurück</button>
        <button class="button" @click="next" v-if="step < 4">Weiter</button>
        <button class="button" @click="addToWaitingList()" v-if="step === 4">
          Auf die Warteliste eintragen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import StepperHeader from "@/components/StepperHeader";
import Customer from "@/components/Customer";
import Participant from "@/components/Participant";
import axios from "axios";
import Preferences from "@/components/Preferences";
import WaitingListSummary from "@/components/WaitingListSummary";

export default {
  name: "WaitingList",
  components: {
    WaitingListSummary,
    Preferences,
    StepperHeader,
    Customer,
    Participant
  },
  props: ["courseId"],
  data() {
    return {
      step: 1,
      customer: {},
      participant: {},
      preferences: {
        bath: [],
        category: [],
        times: [
          { start: "", end: "" },
          { start: "", end: "" },
          { start: "", end: "" },
          { start: "", end: "" },
          { start: "", end: "" },
          { start: "", end: "" },
          { start: "", end: "" }
        ]
      },
      settings: {},
      course: {},
      baths: [],
      categories: []
    };
  },
  computed: {
    clubCategories() {
      return [
        ...new Set(
          this.categories
            .filter(category => {
              if (category.type === "club") return category;
            })
            .map(category => {
              return category.title;
            })
        )
      ];
    }
  },
  methods: {
    addToWaitingList() {
      const vue = this;
      let item = {
        customer: this.customer,
        participant: this.participant,
        preferences: this.preferences
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/public/waitinglist", item)
        .then(() => {
          vue.changeStep(5);
        });
    },
    next() {
      if (this.step === 1 && this.$refs["preferences"].checkForm()) {
        this.step++;
      } else if (this.step === 2 && this.$refs["customer"].checkForm()) {
        this.step++;
      } else if (this.step === 3 && this.$refs["participant"].checkForm()) {
        this.step++;
      }
      this.changeStep(this.step);
    },
    previous() {
      this.step--;
      this.changeStep(this.step);
    },
    changeStep(step) {
      this.step = step;
      this.$refs.stepperHeader.scrollIntoView({ behavior: "smooth" });
    },

    relocateToCourseList() {
      this.$router.push({ name: "courseList" });
    }
  },
  created() {
    axios
      .get(
        process.env.VUE_APP_API_URL +
          "/public/location/" +
          this.axios.defaults.headers["X-LOCATION"] +
          "/settings"
      )
      .then(response => {
        this.settings = response.data;
      });

    const vue = this;
    axios
      .get(process.env.VUE_APP_API_URL + "/public/courses/upcoming")
      .then(response => {
        const courses = [];
        response.data.forEach(item => {
          let dayOfWeeks = [];
          item.timeslots = {};
          item.times.forEach(day => {
            dayOfWeeks.push(day.weekday);
            item.timeslots[day.weekday] = day.time;
          });
          item.dayOfWeeks = dayOfWeeks;
          if (item.type === "club") {
            const now = new Date();
            const nextMonth = new Date(
              now.getFullYear(),
              now.getMonth() + 1,
              1
            );
            const startDate = new Date(item.startDate);
            if (startDate < nextMonth) item.startDate = nextMonth;
          }
          courses.push(item);

          if (vue.categories.indexOf(item.definitionTitle) === -1) {
            vue.categories.push({
              title: item.definitionTitle,
              type: item.type
            });
          }
          if (vue.baths.indexOf(item.location) === -1) {
            vue.baths.push(item.location);
          }

          if (vue.courseId === item.id) {
            vue.preferences.bath.push(item.location);
            vue.preferences.category.push(item.definitionTitle);
          }
        });

        vue.baths.sort();
        vue.categories.sort((a, b) => a.title > b.title);
        vue.course = courses;
      });
  },
  mounted() {
    this.$refs.top.scrollIntoView({ behavior: "smooth" });
  }
};
</script>

<style scoped lang="scss"></style>
