<template>
  <div id="app">
    <h1>{{ companyName }}</h1>
    <div class="callout warning" v-if="isMaintenance">
      Wartungsarbeiten am Donnerstag 28.12.23 und Freitag 29.12.23. In diese
      Zeit steht ihnen die Buchungsfunktion nur eingeschränkt zur Verfügung. In
      der Nacht zwischen 20 und 4 Uhr ist keine Buchung möglich.
    </div>
    <router-view
      :settings="settings"
      v-if="dataFetched && !isMaintenance"
    ></router-view>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  data() {
    return {
      settings: null,
      dataFetched: false
    };
  },
  computed: {
    companyName() {
      return this.settings === null ? "" : this.settings.company;
    },
    isMaintenance() {
      const now = new Date();
      const dateFrom = new Date("2023-12-28 20:00");
      const dateTo = new Date("2023-12-29 04:00");

      if (now >= dateFrom && now <= dateTo) {
        return true;
      }

      return false;
    }
  },
  created() {
    const vue = this;
    localStorage.setItem("locationAlias", this.$route.params.alias);
    const alias = localStorage.getItem("locationAlias");

    if (
      alias !== "undefined" &&
      this.settings !== null &&
      this.settings.id > 0 &&
      this.settings.alias === alias
    ) {
      this.axios.defaults.headers["X-LOCATION"] = this.settings.id;
      this.dataFetched = true;
      if (this.$route.name === "start") {
        this.$router.push({
          name: "courseList",
          props: { alias: alias }
        });
      }
    } else if (alias !== "undefined") {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/public/locations/" +
            alias +
            "/settings"
        )
        .then(response => {
          vue.settings = response.data;
          vue.axios.defaults.headers["X-LOCATION"] = response.data.id;
          vue.dataFetched = true;
          if (this.$route.name === "start") {
            vue.$router.push({
              name: "courseList",
              props: { alias: response.data.alias }
            });
          }
        });
    }
  }
};
</script>

<style lang="scss">
html {
  font-size: 18px;
}

body {
  background-color: #f4f4f4;
  padding: 0;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 300;
  line-height: 1.8;
  color: #000;

  h1 {
    margin-top: 0;
    font-size: 3rem;
    color: #f18904;
    @media (max-width: 640px) {
      font-size: 2.25rem;
    }
  }

  h2 {
    font-size: 2.5rem;
    margin-bottom: 2.5rem;
    color: #f18904;
    @media (max-width: 640px) {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.7rem;
    margin-bottom: 2.5rem;
    color: #f18904;
    @media (max-width: 640px) {
      font-size: 1.5rem;
    }
  }

  a {
    color: #f18904;
    font-weight: normal;
    text-decoration: none;
  }

  strong {
    font-weight: 600;
  }

  button {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 1rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: background-color 0.25s ease-out, color 0.25s ease-out;
    font-family: NeoTechProMedium, sans-serif;
    font-size: 0.9rem;
    font-weight: 700;
    -webkit-appearance: none;
    line-height: 1;
    text-align: center;
    cursor: pointer;
  }

  .callout {
    position: relative;
    margin: 0 0 1rem 0;
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 0.25rem;

    &.success {
      color: #418847;
      border: 1px solid #418847;
      background-color: #ffffff;
    }

    &.warning {
      background-color: #fff3d9;
      color: #000;
    }

    &.alert {
      color: #ff0000;
      border: 1px solid #ff0000;
      background-color: #ffffff;
    }

    .title {
      font-size: 1.25rem;
      color: #f18904;
    }
  }

  .pagination {
    ul {
      text-align: center;
      margin-top: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin-right: 0.25em;
        display: inline-block;

        button {
          background-color: #f18904;
          color: #fff;

          &[disabled] {
            opacity: 0.25;
            cursor: not-allowed;
          }

          &.active {
            background-color: #f4f4f4;
            color: #000000;
          }
        }
      }
    }
  }

  .badge {
    display: inline-block;
    min-width: 2.1em;
    padding: 0.3em;
    border-radius: 50%;
    font-size: 0.875rem;
    text-align: center;
    background-color: #8bb1c4;
    color: #000;
  }

  label {
    display: block;
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.8;
    color: #000;

    &.error {
      color: #ff0000;
    }
  }

  select {
    height: 3.5444444444rem;
    margin: 0 0 2rem;
    padding: 1rem 3rem 1rem 1rem;
    appearance: none;
    border: 1px solid #cacaca;
    border-radius: 0.25rem;
    background-color: #f7f8fa;
    font-family: inherit;
    font-size: 0.9rem;
    line-height: 1.8;
    color: #000;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
    background-origin: content-box;
    background-position: right -2rem center;
    background-repeat: no-repeat;
    background-size: 9px 6px;
    max-width: 100%;

    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  }

  input[type="text"],
  textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 3.5444444444rem;
    margin: 0 0 2rem;
    padding: 1rem;
    border: 1px solid #cacaca;
    border-radius: 0.25rem;
    background-color: #f7f8fa;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    font-family: inherit;
    font-size: 0.8888888889rem;
    font-weight: 300;
    line-height: 1.8;
    color: #000;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
    appearance: none;
  }

  textarea {
    min-height: 9rem;
  }

  input[type="checkbox"] {
    width: 2rem;
    height: 2rem;
    margin: 0;
    margin-right: 0.5rem;
  }

  input[type="radio"] {
    margin: 0 0 2rem;

    & + label {
      display: inline-block;
      vertical-align: baseline;
      margin-left: 1rem;
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }

  input:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 0.25rem;

    thead {
      background-color: #fff;
      color: #f18904;
      font-weight: 600;
    }

    tbody {
      tr {
        border-bottom: 1px solid #000;
      }
    }

    tfoot {
      color: #f18904;
      font-weight: 600;

      tr {
        td {
          &.price {
            text-align: right;
          }
        }
      }
    }
  }
}

.buttons {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;

  button {
    background-color: #f18904;
    color: #ffffff;

    @media (max-width: 640px) {
      width: 8rem;
    }
  }
}

#app {
  margin: 0 auto;
  padding: 2rem;
  max-width: 1200px;
  background-color: #ffffff;
  overflow: hidden;
}
</style>
