<template>
  <div class="list">
    <div v-if="this.items.length === 0">
      <div class="callout warning">
        <p class="title">Keine Kurse vorhanden</p>
        <p class="text">
          Mit den hinterlegten Filtern sind leider Kurse vorhanden. Sie können
          uns aber gerne per E-Mail kontaktieren mit Ihren Wünschen.<br />
          <br />
          <a :href="'mailto:' + settings.email">{{ settings.email }}</a>
        </p>
      </div>
    </div>
    <div v-else class="course" v-for="item of this.currentItems" :key="item.id">
      <h3 class="title">{{ item.title }}</h3>
      <div class="bath">Bad: {{ item.location }}</div>
      <div class="instructor">Trainer: {{ item.instructor }}</div>
      <div class="date">
        <template
          v-if="item.type === 'course' || item.type === 'hybrid-course'"
        >
          Zeitraum: {{ item.startDate | formatDate }} -
          {{ item.endDate | formatDate }}
        </template>
        <template v-if="item.type === 'club'">
          Startdatum: {{ item.startDate | formatDate }}
        </template>
      </div>
      <div class="courseId">Kurs-ID: {{ item.id }}</div>
      <div class="price">
        Preise: {{ item.price | formatCurrency
        }}<template v-if="item.type === 'club'"> pro Monat</template>
      </div>
      <div class="duration">Dauer: {{ item.duration }} Minuten</div>
      <div class="days">
        <div
          class="day"
          v-for="weekday in weekdays"
          :class="checkDayOfWeek(item, weekday.dow) ? '' : 'disabled'"
          :key="item.id + '-' + weekday.dow"
        >
          {{ weekday.text }}
        </div>
        <div
          class="time"
          v-for="weekday in weekdays"
          :class="checkDayOfWeek(item, weekday.dow) ? '' : 'disabled'"
          :key="item.id + '-time-' + weekday.dow"
        >
          {{ getTimeByWeekday(item, weekday.dow) }}
        </div>
      </div>

      <div class="area">
        <button v-if="item.area === 'adult'" class="button adult">
          Erwachsene
        </button>
        <button v-else-if="item.area === 'child'" class="button child">
          Kinder
        </button>
        <button v-else-if="item.area === 'family'" class="button family">
          Familie
        </button>
      </div>

      <CourseWorkload
        :load="item.workload"
        :capacity="item.capacity"
        class="space"
      ></CourseWorkload>

      <div class="order">
        <template v-if="item.type === 'club'">
          <router-link
            v-if="item.capacity - item.workload > 0"
            :to="{ name: 'booking', params: { courseId: item.id } }"
            tag="button"
            class="button"
            >Buchen
          </router-link>
          <router-link
            v-else
            :to="{
              name: 'waitingList',
              params: {
                baths: baths,
                categories: categories,
                courseId: item.id
              }
            }"
            tag="button"
            class="button"
            >Warteliste
          </router-link>
        </template>
        <template v-else>
          <router-link
            v-if="item.capacity - item.workload > 0"
            :to="{ name: 'booking', params: { courseId: item.id } }"
            tag="button"
            class="button"
            >Buchen
          </router-link>
          <button class="button" v-else disabled>Buchen</button>
        </template>
      </div>
    </div>
    <div class="pagination">
      <ul>
        <li @click="changePage(currentPage - 1)">
          <button class="button" :disabled="currentPage <= 1">Zurück</button>
        </li>
        <template v-for="page in maxPages">
          <li
            :key="page"
            @click="changePage(page)"
            v-if="page + 2 >= currentPage && page - 2 <= currentPage"
          >
            <button :class="'button' + (currentPage === page ? ' active' : '')">
              {{ page }}
            </button>
          </li>
        </template>
        <li @click="changePage(currentPage + 1)">
          <button class="button" :disabled="currentPage >= maxPages">
            Weiter
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CourseWorkload from "@/components/CourseWorkload.vue";

export default {
  name: "CourseListing",
  components: { CourseWorkload },
  props: ["items", "settings"],
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
      weekdays: [
        { dow: 1, text: "MO" },
        { dow: 2, text: "DI" },
        { dow: 3, text: "MI" },
        { dow: 4, text: "DO" },
        { dow: 5, text: "FR" },
        { dow: 6, text: "SA" },
        { dow: 0, text: "SO" }
      ]
    };
  },
  computed: {
    currentItems: function() {
      const vue = this;
      if (vue.items.length > 0)
        return vue.items.slice(
          (vue.currentPage - 1) * vue.itemsPerPage,
          vue.currentPage * vue.itemsPerPage
        );
      return [];
    },
    maxPages: function() {
      if (this.items !== undefined && this.items.length > 0)
        return parseInt(Math.ceil(this.items.length / this.itemsPerPage));
      return 0;
    }
  },
  methods: {
    changePage(page) {
      this.currentPage = page;
      this.$refs.top.scrollIntoView({ behavior: "smooth" });
    },
    checkDayOfWeek(item, dow) {
      return item.dayOfWeeks.indexOf(dow) !== -1;
    },
    getTimeByWeekday(item, dow) {
      if (item.timeslots[dow] !== undefined) {
        return item.timeslots[dow];
      }

      return "00:00";
    }
  }
};
</script>

<style scoped lang="scss">
.list {
  .course {
    display: grid;
    grid-column-gap: 1rem;
    grid-row-gap: 0.25rem;
    grid-template-areas:
      "title title days"
      "bath	course-id	area"
      "instructor	price	area"
      "date	duration	area"
      "space	space	button";
    margin-bottom: 3rem;

    @media (max-width: 640px) {
      grid-template-areas:
        "title title"
        "days days"
        "bath bath"
        "course-id course-id"
        "instructor instructor"
        "price price"
        "date date"
        "duration duration"
        "space space"
        "area button";
    }

    .title {
      grid-area: title;
      margin: 0;
      color: #f18904;
      @media (max-width: 640px) {
        font-size: 1.25rem;
        margin-bottom: 0.5rem;
      }
    }

    .days {
      grid-area: days;

      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: repeat(2, 1fr);

      .day,
      .time {
        padding: 0.25rem;
        color: #f18904;
        font-weight: bold;
        text-align: center;
        @media (max-width: 640px) {
          font-size: 0.7rem;
        }

        &.disabled {
          font-weight: normal;
          opacity: 0.2;
          color: black;
        }
      }
    }

    .bath {
      grid-area: bath;
    }

    .courseId {
      grid-area: course-id;
    }

    .area {
      grid-area: area;
      text-align: right;
      margin: 1rem 0;
      @media (max-width: 640px) {
        margin-top: 1rem;
        text-align: left;
      }

      button {
        background-color: #be4023;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.2rem;

        &.family {
          background-color: #0078bf;
        }

        &.child {
          background-color: #00376d;
        }
      }
    }

    .instrcutor {
      grid-area: instructor;
    }

    .price {
      grid-area: price;
    }

    .date {
      grid-area: date;
    }

    .duration {
      grid-area: duration;
    }

    .space {
      grid-area: space;
    }

    .order {
      grid-area: button;
      text-align: right;

      @media (max-width: 640px) {
        margin-top: 1rem;
      }

      button {
        background-color: #f18904;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
      }
    }
  }
}
</style>
