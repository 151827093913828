<template>
  <div>
    <div class="summary">
      <div class="headline">Ihre Daten</div>
      <div class="edit">
        <button class="button" @click="changeStep(1)">Bearbeiten</button>
      </div>
      <div class="items">
        <div class="label">Geschlecht</div>
        <div class="value">{{ getSalutation(booking.customer.gender) }}</div>
      </div>
      <div class="items">
        <div class="label">Name</div>
        <div class="value">
          {{ booking.customer.firstname }} {{ booking.customer.lastname }}
        </div>
      </div>
      <div class="items">
        <div class="label">Adresse</div>
        <div class="value">
          {{ booking.customer.street }} {{ booking.customer.streetNumber
          }}<br />{{ booking.customer.postal }} {{ booking.customer.city }}
        </div>
      </div>
      <div class="items">
        <div class="label">E-Mail</div>
        <div class="value">{{ booking.customer.email }}</div>
      </div>
      <div class="items">
        <div class="label">Telefon</div>
        <div class="value">{{ booking.customer.phone }}</div>
      </div>
    </div>
    <div class="summary">
      <div class="headline">Daten des Teilnehmers</div>
      <div class="edit">
        <button class="button" @click="changeStep(2)">Bearbeiten</button>
      </div>
      <div class="items">
        <div class="label">Geschlecht</div>
        <div class="value">{{ getSalutation(booking.participant.gender) }}</div>
      </div>
      <div class="items">
        <div class="label">Name</div>
        <div class="value">
          {{ booking.participant.firstname }} {{ booking.participant.lastname }}
        </div>
      </div>
      <div class="items">
        <div class="label">Geburtstag</div>
        <div class="value">{{ booking.participant.dateOfBirth }}</div>
      </div>
      <div class="items">
        <div class="label">Weitere Informationen</div>
        <div class="value">{{ booking.participant.moreInformation }}</div>
      </div>
    </div>
    <div class="summary">
      <div class="headline">Zahlweise</div>
      <div class="edit">
        <button class="button" @click="changeStep(3)">Bearbeiten</button>
      </div>
      <div class="items">
        <div class="label">Zahlweise</div>
        <div class="value">
          {{ getPaymentMethod(booking.payment.paymentMethod) }}
        </div>
      </div>
      <div class="items">
        <div class="label">Gutschein</div>
        <div class="value">{{ coupon }}</div>
      </div>
    </div>
    <div class="summary">
      <div class="headline">Kurs</div>
      <div class="edit">
        <button class="button" @click="changeStep(4)">Bearbeiten</button>
      </div>
      <div class="items">
        <div class="label">ID</div>
        <div class="value">{{ course.id }}</div>
      </div>
      <div class="items">
        <div class="label">Titel</div>
        <div class="value">{{ course.title }}</div>
      </div>
      <div class="items">
        <div class="label">Preis</div>
        <div class="value">{{ price | formatCurrency }}</div>
      </div>
      <div class="items" v-if="event > 0">
        <div class="label">Einzeltermin</div>
        <div class="value">
          {{ eventDateTime.dow | formatDateWeekday }} {{ eventDateTime.date }}
          {{ eventDateTime.time }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookingSummary",
  props: ["booking", "course", "event"],
  data() {
    return {
      paymentMethod: ""
    };
  },
  computed: {
    coupon() {
      if (this.booking.payment.coupon !== undefined)
        return this.booking.payment.coupon.toUpperCase();
      return "";
    },
    price() {
      if (this.event === 0) return this.course.price;
      return this.course.priceUnit;
    },
    eventDateTime() {
      if (this.event !== 0) {
        return this.course.events.find(event => event.id === this.event);
      }
      return {};
    }
  },
  methods: {
    changeStep(value) {
      this.$emit("changeStep", value);
    },
    getPaymentMethod(method) {
      if (method === "remittance") return "Überweisung";
      if (method === "paypal") return "PayPal";
      if (method === "creditcard") return "Kreditkarte";
      if (method === "directdebit") return "Lastschrifteinzug";
      return "";
    },
    getSalutation(value) {
      if (value === "female") return "Weiblich";
      if (value === "male") return "Männlich";
      return "Divers";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.summary {
  margin-bottom: 4rem;
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 0.25rem;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }
  .headline {
    grid-column: 1/3;
    font-size: 1.7rem;
    font-weight: 600;
    color: #f18904;
    @media (max-width: 640px) {
      grid-column: 1/4;
      font-size: 1.25rem;
    }
  }

  .edit {
    grid-column: 3;
    @media (max-width: 640px) {
      grid-column: 1/4;
    }

    .button {
      background-color: #f18904;
      color: #ffffff;
    }
  }

  .items {
    grid-column: 1/3;
    display: grid;
    grid-column-gap: 2rem;
    grid-row-gap: 0.25rem;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px solid #000000;
    padding: 0.25rem;

    @media (max-width: 640px) {
      grid-column: 1/4;
    }

    .label {
      grid-column: 1;
    }

    .value {
      grid-column: 2;

      @media (max-width: 640px) {
        grid-column: 2/4;
      }
    }
  }
}
</style>
