<template>
  <div class="waiting-list" ref="top">
    <div class="grid-container">
      <h2>Wartelisteneintrag bearbeiten</h2>
      <div class="grid-x grid-margin-x grid-margin-y">
        <div class="cell small-12 medium-6 large-6">
          <p>
            Liebe Eltern. Liebe Teilnehmer*innen,<br />
            <br />
            vielen Dank für Ihr Interesse an unseren Schwimm-Clubs.<br />
            Bitte tragen Sie für ein schnelles Ergebnis alle möglichen Zeiten
            und Standorte ein. Sie erhalten nach Ihren Präferenzen passende
            Clubangebote. Nach Erhalt können Sie diese für eine verbindliche
            Buchung bestätigen. !Ein Eintrag in die Warteliste ist keine
            Buchung!<br />
            <br />
            Wir können keine Prognosen über Wartezeiten auf diesem Wege
            ausgeben. Erfahrungsgemäß beträgt die Wartezeit durchschnittlich 2
            bis 3 Monate.
          </p>
        </div>
      </div>
    </div>

    <div class="form">
      <div class="stepper">
        <div class="header" ref="stepperHeader">
          <StepperHeader
            name="Präferenzen"
            number="1"
            :step="step"
          ></StepperHeader>
        </div>
        <div class="content" ref="contentTop">
          <div class="step" v-if="step === 1">
            <div class="preferences">
              <Preferences
                :baths="baths"
                :categories="clubCategories"
                :preferences.sync="preferences"
                ref="preferences"
              ></Preferences>
            </div>
          </div>
          <div class="step" v-else-if="step === 2">
            <div class="callout success">
              <h5>Vielen Dank für Ihre Änderung</h5>
              <p>
                Bitte fügen Sie unsere E-Mailadresse {{ settings.email }} in Ihr
                Adressbuch hinzu. So gewährleisten Sie ein Zugang aller
                relevanten Mails.
              </p>
              <a href="https://www.swym-shop.de" target="_blank"
                >Passende Schwimmbrillen, Schwimmsachen und vieles mehr finden
                Sie auf unserer Shop-Seite.</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="button" @click="relocateToCourseList">
          Zurück zur Übersicht
        </button>
        <button class="button" @click="saveWaitingList()" v-if="step === 1">
          Änderungen speichern
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import StepperHeader from "@/components/StepperHeader";
import axios from "axios";
import Preferences from "@/components/Preferences";

export default {
  name: "Booking",
  components: {
    Preferences,
    StepperHeader
  },
  props: ["waitingListToken"],
  data() {
    return {
      step: 1,
      preferences: {
        bath: [],
        category: [],
        times: [
          { start: "", end: "" },
          { start: "", end: "" },
          { start: "", end: "" },
          { start: "", end: "" },
          { start: "", end: "" },
          { start: "", end: "" },
          { start: "", end: "" }
        ]
      },
      settings: {},
      course: {},
      baths: [],
      categories: []
    };
  },
  computed: {
    clubCategories() {
      return this.categories.filter(category => {
        if (/.*club.*/i.test(category)) return category;
      });
    }
  },
  methods: {
    saveWaitingList() {
      if (this.$refs["preferences"].checkForm()) {
        const vue = this;
        const item = {
          token: this.waitingListToken,
          preferences: this.preferences
        };
        axios
          .put(
            process.env.VUE_APP_API_URL + "/public/waitinglist/" + item.token,
            item
          )
          .then(() => {
            vue.changeStep(2);
          });
      }
    },
    addToWaitingList() {
      const vue = this;
      let item = {
        customer: this.customer,
        participant: this.participant,
        preferences: this.preferences
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/public/waitinglist", item)
        .then(() => {
          vue.changeStep(5);
        });
    },
    next() {
      if (this.step === 1 && this.$refs["preferences"].checkForm()) {
        this.step++;
      } else if (this.step === 2 && this.$refs["customer"].checkForm()) {
        this.step++;
      } else if (this.step === 3 && this.$refs["participant"].checkForm()) {
        this.step++;
      }
      this.changeStep(this.step);
    },
    changeStep(step) {
      this.step = step;
      this.$refs.stepperHeader.scrollIntoView({ behavior: "smooth" });
    },

    relocateToCourseList() {
      this.$router.push({ name: "courseList" });
    }
  },
  created() {
    axios
      .get(
        process.env.VUE_APP_API_URL +
          "/public/location/" +
          this.axios.defaults.headers["X-LOCATION"] +
          "/settings"
      )
      .then(response => {
        this.settings = response.data;
      });

    if (this.waitingListToken !== undefined) {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/public/waitinglist/" +
            this.waitingListToken
        )
        .then(response => {
          this.preferences = response.data.preferences;
        })
        .catch(() => {
          this.relocateToCourseList();
        });
    }

    const vue = this;
    axios
      .get(process.env.VUE_APP_API_URL + "/public/courses/upcoming")
      .then(response => {
        const courses = [];
        response.data.forEach(item => {
          let dayOfWeeks = [];
          item.timeslots = {};
          item.times.forEach(day => {
            dayOfWeeks.push(day.weekday);
            item.timeslots[day.weekday] = day.time;
          });
          item.dayOfWeeks = dayOfWeeks;
          if (item.type === "club") {
            const now = new Date();
            const nextMonth = new Date(
              now.getFullYear(),
              now.getMonth() + 1,
              1
            );
            const startDate = new Date(item.startDate);
            if (startDate < nextMonth) item.startDate = nextMonth;
          }
          courses.push(item);

          if (vue.categories.indexOf(item.definitionTitle) === -1) {
            vue.categories.push(item.definitionTitle);
          }
          if (vue.baths.indexOf(item.location) === -1) {
            vue.baths.push(item.location);
          }
        });

        vue.baths.sort();
        vue.categories.sort();
        vue.course = courses;
      });
  },
  mounted() {
    this.$refs.top.scrollIntoView({ behavior: "smooth" });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
