<template>
  <div>
    <h3>Übersicht</h3>
    <div class="list head">
      <div class="course">Kurs</div>
      <div class="event"></div>
      <div class="price">Preis</div>
    </div>
    <div class="list">
      <div class="course">
        <strong>{{ course.title }}</strong
        ><br />Kurs-ID: {{ course.id }}
      </div>
      <div class="event">
        <div v-if="event !== 0">
          <strong>Einzeltermin:</strong><br />
          {{ eventDateTime.date }} {{ eventDateTime.time }}
        </div>
      </div>
      <div class="price">
        {{ price | formatCurrency }}
      </div>
    </div>
    <div class="pricing" v-if="settings.smallBusinessRegulation === false">
      <div class="label">Zwischensumme:</div>
      <div class="value">{{ netto | formatCurrency }}</div>
    </div>
    <div class="pricing" v-if="settings.smallBusinessRegulation === false">
      <div class="label">zzgl MwSt 19%:</div>
      <div class="value">{{ tax | formatCurrency }}</div>
    </div>
    <div class="pricing" v-if="couponChecked && course.type !== 'club'">
      <div class="label">abzüglich Gutschein:</div>
      <div class="value">- {{ couponValue | formatCurrency }}</div>
    </div>
    <div class="pricing">
      <div class="label">Gesamtbetrag:</div>
      <div class="value">
        <strong>{{ total | formatCurrency }}</strong>
      </div>
    </div>
    <div
      class="smallBusinessRegulation"
      v-if="settings.smallBusinessRegulation === true"
    >
      Gemäß der Kleinunternehmerregelung aus § 19 UStG ist der Rechnungsbetrag
      nicht umsatzsteuerpflichtig.
    </div>
    <div class="payment">
      <div class="paymentMethod">
        <h3>Zahlweise</h3>
        <input
          type="radio"
          id="remittance"
          v-model="paymentProxy.paymentMethod"
          value="remittance"
          :class="checkError('paymentMethod')"
        />
        <label for="remittance" :class="checkError('paymentMethod')"
          >Überweisung</label
        >
        <!--<input type="radio" id="paypal" v-model="paymentProxy.paymentMethod" value="paypal" :class="checkError('paymentMethod')">
            <label for="paypal" :class="checkError('paymentMethod')">PayPal</label>
            <input type="radio" id="creditcard" v-model="paymentProxy.paymentMethod" value="creditcard" :class="checkError('paymentMethod')">
            <label for="creditcard" :class="checkError('paymentMethod')">Kreditkarte</label>
            <input type="radio" id="directdebit" v-model="paymentProxy.paymentMethod" value="directdebit" :class="checkError('paymentMethod')">
            <label for="directdebit" :class="checkError('paymentMethod')">Lastschrifteinzug</label>

            <div class="callout secondary">Sie haben die Möglichkeit am Ende des Buchungsprozesses online per PayPal, Kreditkarte oder Lastschrift zu zahlen.</div>-->

        <div v-if="paymentProxy.paymentMethod === 'remittance'">
          <p v-if="course.type === 'course' || course.type === 'hybrid-course'">
            Bitte überweisen Sie den Betrag von
            {{ total | formatCurrency }} auf das folgende Konto:
          </p>
          <p v-else>
            <template v-if="paymentProxy.coupon === undefined"
              >Der Betrag von {{ price | formatCurrency }} wird monatlich
              fällig. Bitte überweisen die die erste Rate auf das folgende
              Konto:</template
            >
            <template
              v-if="paymentProxy.coupon !== undefined && couponValue <= total"
              >Der Betrag von {{ total | formatCurrency }} wird monatlich
              fällig. Bitte überweisen die die erste Rate über
              {{ (total - couponValue) | formatCurrency }} auf das folgende
              Konto:</template
            >
            <template
              v-if="paymentProxy.coupon !== undefined && couponValue > total"
              >Der Betrag von {{ total | formatCurrency }} wird monatlich
              fällig. Die erste Rate wird mit Ihrem Gutschein beglichen. Für die
              weiteren Zahlungen erhalten Sie monatlich eine
              Übersicht.</template
            >
          </p>

          <p>
            IBAN: {{ settings.iban }}<br />
            BIC: {{ settings.bic }}<br />
            Kontoinhaber: {{ settings.owner }}<br />
            Bank: {{ settings.bank_name }}<br />
            <br />
            Sie erhalten diese Information auch per E-Mail.
          </p>
        </div>
      </div>
      <div class="coupon">
        <div class="widget">
          <label for="coupon" :class="checkError('coupon')">Gutschein</label>
          <input
            type="text"
            id="coupon"
            v-model="paymentProxy.coupon"
            :disabled="couponChecked"
          />
        </div>
        <div class="buttons">
          <button v-if="!couponChecked" class="button" @click="checkCoupon">
            Verrechnen</button
          ><button v-if="couponChecked" class="button" @click="resetCoupon">
            Löschen
          </button>
        </div>
        <p class="couponText">
          Wenn Sie einen bestehenden Gutscheincode haben, können Sie diesen hier
          eingeben und verrechnen. Der Betrag wird im Anschluss an die Buchung
          automatisch verrechnet.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BookingPayment",
  props: ["payment", "course", "settings", "event"],
  data() {
    return {
      paymentValue: this.payment,
      errors: [],
      couponValue: 0,
      couponChecked: false
    };
  },
  computed: {
    netto() {
      return (this.price / 1.19).toFixed(2);
    },
    tax() {
      return (this.price - this.netto).toFixed(2);
    },
    total() {
      if (this.couponValue > 0 && this.course.type !== "club")
        return this.price - this.couponValue;
      return this.price;
    },
    paymentProxy: {
      get: function() {
        return this.paymentValue;
      },
      set: function(value) {
        this.paymentValue = value;
        this.$emit("update:payment", this.paymentValue);
      }
    },
    price() {
      if (this.event === 0) return this.course.price;
      return this.course.priceUnit;
    },
    eventDateTime() {
      if (this.event !== 0) {
        return this.course.events.find(event => event.id === this.event);
      }
      return {};
    }
  },
  methods: {
    checkForm() {
      let errors = [];
      if (
        this.paymentProxy.paymentMethod === undefined ||
        this.paymentProxy.paymentMethod === ""
      )
        errors.push("paymentMethod");

      this.errors = errors;
      return this.errors.length === 0;
    },
    checkError(field) {
      return this.errors.indexOf(field) !== -1 ? "error" : "";
    },
    checkCoupon() {
      this.couponChecked = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/coupons/check/" +
            this.paymentProxy.coupon
        )
        .then(response => {
          if (this.course.type !== "club" && response.data.value > this.total)
            this.couponValue = this.total;
          this.couponValue = response.data.value;
        });
    },
    resetCoupon() {
      this.paymentProxy.coupon = undefined;
      this.couponValue = 0;
      this.couponChecked = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 1px solid #000;

  @media (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  &.head {
    background-color: #fff;
    color: #f18904;
    font-weight: 600;
    border-bottom: 0;
  }

  .event {
    @media (max-width: 640px) {
      grid-column: 1;
    }
  }

  .price {
    text-align: right;

    @media (max-width: 640px) {
      grid-column: 2;
      grid-row: 1/2;
    }
  }
}

.pricing {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: #f18904;

  @media (max-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .label {
    grid-column: 2;
    text-align: right;

    @media (max-width: 640px) {
      grid-column: 1;
    }
  }

  .value {
    grid-column: 3;
    text-align: right;

    @media (max-width: 640px) {
      grid-column: 2;
    }
  }
}
.payment {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 2rem;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr;
  }
}
.coupon {
  grid-column: 2;
  @media (max-width: 640px) {
    grid-column: 1;
    grid-row: 1;
  }
}

.paymentMethod {
  grid-column: 1;
  @media (max-width: 640px) {
    grid-column: 1;
    grid-row: 2;
  }
}

.smallBusinessRegulation {
  padding: 1rem 0;
  text-align: right;
}
</style>
