<template>
  <div class="course_list" ref="top">
    <h2>Last Call Kurse</h2>
    <CourseFilter
      :courses="course"
      :freeCourses.sync="filterFreeCourses"
      :bath.sync="filterBath"
      :category.sync="filterCategory"
      :categories="categories"
      :baths="baths"
    ></CourseFilter>
    <CourseListing
      :items="filteredCourses"
      :settings="settings"
    ></CourseListing>
  </div>
</template>

<script>
import axios from "axios";
import CourseFilter from "@/components/CourseFilter";
import CourseListing from "@/components/CourseListing.vue";

export default {
  name: "LastCallCourseList",
  components: { CourseListing, CourseFilter },
  props: ["settings"],
  data() {
    return {
      course: [],
      currentPage: 1,
      items: 10,
      weekdays: [
        { dow: 1, text: "MO" },
        { dow: 2, text: "DI" },
        { dow: 3, text: "MI" },
        { dow: 4, text: "DO" },
        { dow: 5, text: "FR" },
        { dow: 6, text: "SA" },
        { dow: 0, text: "SO" }
      ],
      filterFreeCourses: false,
      filterBath: "",
      filterCategory: "",
      categories: [],
      baths: []
    };
  },
  computed: {
    filteredCourses: function() {
      if (this.course === undefined) return [];
      let courses = this.course;
      if (this.filterBath !== "")
        courses = courses.filter(item => item.location === this.filterBath);
      if (this.filterCategory !== "")
        courses = courses.filter(
          item => item.definitionTitle === this.filterCategory
        );
      if (this.filterFreeCourses)
        courses = courses.filter(item => item.workload < item.capacity);

      return courses;
    }
  },
  mounted() {
    const vue = this;
    axios
      .get(process.env.VUE_APP_API_URL + "/public/courses/upcoming?last-minute")
      .then(response => {
        const courses = [];
        response.data.forEach(item => {
          let dayOfWeeks = [];
          item.timeslots = {};
          item.times.forEach(day => {
            dayOfWeeks.push(day.weekday);
            item.timeslots[day.weekday] = day.time;
          });
          item.dayOfWeeks = dayOfWeeks;
          if (item.type === "club") {
            const now = new Date();
            const nextMonth = new Date(
              now.getFullYear(),
              now.getMonth() + 1,
              1
            );
            const startDate = new Date(item.startDate);
            if (startDate < nextMonth) item.startDate = nextMonth;
          }
          courses.push(item);

          if (vue.categories.indexOf(item.definitionTitle) === -1) {
            vue.categories.push(item.definitionTitle);
          }
          if (vue.baths.indexOf(item.location) === -1) {
            vue.baths.push(item.location);
          }
        });

        vue.baths.sort();
        vue.categories.sort();
        vue.course = courses;

        if (vue.$route.params.filterCategory !== undefined) {
          vue.filterCategory = vue.$route.params.filterCategory;
        }
      });
  },
  watch: {
    filteredCourses: function() {
      if (this.currentPage > this.maxPages) this.changePage(1);
    }
  },
  created() {
    if (this.axios.defaults.headers["X-LOCATION"] === undefined) {
      this.$router.push({ name: "start" });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
