<template>
  <div>
    <div class="error">
      <div class="callout alert" v-if="errors.length > 0">
        Bitte korrigieren Sie die rot markierten Felder.
      </div>
    </div>
    <div class="participant">
      <div class="gender">
        <label for="gender" :class="checkError('gender')">Geschlecht</label>
        <select id="gender" name="gender" v-model="participantProxy.gender">
          <option value=""></option>
          <option value="diverse">Divers</option>
          <option value="male">Männlich</option>
          <option value="female">Weiblich</option>
        </select>
      </div>
      <div class="dateOfBirth">
        <label for="dateOfBirth" :class="checkError('dateOfBirth')"
          >Geburtstag</label
        >
        <input
          type="text"
          id="dateOfBirth"
          name="dateOfBirth"
          v-model="participantProxy.dateOfBirth"
        />
      </div>
      <div class="firstname">
        <label for="firstname" :class="checkError('lastname')">Vorname</label>
        <input
          type="text"
          id="firstname"
          name="firstname"
          v-model="participantProxy.firstname"
        />
      </div>
      <div class="lastname">
        <label for="lastname" :class="checkError('lastname')">Nachname</label>
        <input
          type="text"
          id="lastname"
          name="lastname"
          v-model="participantProxy.lastname"
        />
      </div>
      <div class="moreInformation">
        <label for="moreInformation"
          >Weitere Informationen zum Teilnehmer</label
        >
        <textarea
          id="moreInformation"
          name="moreInformation"
          v-model="participantProxy.moreInformation"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Participant",
  props: ["participant"],
  data() {
    return {
      participantValue: this.participant,
      errors: []
    };
  },
  computed: {
    participantProxy: {
      set: function(value) {
        this.participantValue = value;
        this.$emit("update:participant", this.participantValue);
      },
      get: function() {
        return this.participantValue;
      }
    }
  },
  methods: {
    checkForm() {
      let errors = [];
      if (
        this.participantProxy.gender === undefined ||
        this.participantProxy.gender === ""
      )
        errors.push("gender");
      if (
        this.participantProxy.firstname === undefined ||
        this.participantProxy.firstname === ""
      )
        errors.push("firstname");
      if (
        this.participantProxy.lastname === undefined ||
        this.participantProxy.lastname === ""
      )
        errors.push("lastname");
      if (
        this.participantProxy.dateOfBirth === undefined ||
        this.participantProxy.dateOfBirth === "" ||
        !/^\d{2}[.]\d{2}[.]\d{4}$/.test(this.participantProxy.dateOfBirth)
      )
        errors.push("dateOfBirth");

      this.errors = errors;
      return this.errors.length === 0;
    },
    checkError(field) {
      return this.errors.indexOf(field) !== -1 ? "error" : "";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.participant {
  display: grid;
  grid-column-gap: 2rem;
  grid-row-gap: 0.25rem;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 2fr;
  grid-template-areas:
    "gender dateOfBirth . . . . "
    "firstname firstname lastname lastname . ."
    "moreInformation moreInformation moreInformation moreInformation . .";

  @media (max-width: 640px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 2fr;
    grid-template-areas:
      "gender dateOfBirth"
      "firstname firstname"
      "lastname lastname"
      "moreInformation moreInformation";
  }

  .gender {
    grid-area: gender;
  }

  .dateOfBirth {
    grid-area: dateOfBirth;
  }

  .firstname {
    grid-area: firstname;
  }

  .lastname {
    grid-area: lastname;
  }

  .moreInformation {
    grid-area: moreInformation;
  }
}
</style>
