<template>
  <div class="waiting-list" ref="top">
    <div class="grid-container">
      <h2>Kursübersicht</h2>
    </div>

    <div class="form">
      <table>
        <thead>
          <tr>
            <th>Zeit</th>
            <th>Montag</th>
            <th>Dienstag</th>
            <th>Mittwoch</th>
            <th>Donnerstag</th>
            <th>Freitag</th>
            <th>Samstag</th>
            <th>Sonntag</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="index in 16" :key="index">
            <td>{{ index + 6 }}:00</td>
            <td v-for="dow in 7" :key="dow">
              <div
                class="event"
                v-for="event in getEventsByDowAndTimeslot(dow, index + 6)"
                :key="event.id"
              >
                <router-link :to="getBookingRoute(event)">
                  <div class="title">{{ event.courseTitle }}</div>
                  <div class="capacity">
                    {{ event.participants }} von
                    {{ event.participantsMax }} belegt
                  </div>
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CourseOverview",
  components: {},
  data() {
    return {
      events: {},
      today: new Date()
    };
  },
  computed: {},
  methods: {
    getBookingRoute(event) {
      if (
        undefined !== event &&
        event.participants >= event.participantsMax &&
        event.courseType === "club"
      )
        return { name: "waitingList" };
      if (undefined !== event)
        return {
          name: "booking",
          params: { courseId: event.courseId, eventId: event.id }
        };
      return {};
    },
    getMonday(date) {
      date = new Date(date);
      const day = date.getDay(),
        diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
      return new Date(date.setDate(diff));
    },
    getEventsByDowAndTimeslot(dow, timeslot) {
      let monday = this.getMonday(this.today);
      monday.setDate(monday.getDate() + dow - 1);
      const month = monday.getMonth() + 1;
      if (
        this.events[month + "-" + monday.getDate()] === undefined ||
        this.events[month + "-" + monday.getDate()][timeslot] === undefined
      )
        return [];
      return this.events[month + "-" + monday.getDate()][timeslot];
    },
    prepareEventData(data) {
      let events = {};
      data.forEach(event => {
        if (event.courseType === "club") {
          const startDate = new Date(event.startDate);
          const month = startDate.getMonth() + 1;
          const day = startDate.getDate();
          const hour = startDate.getHours();
          if (events[month + "-" + day] === undefined)
            events[month + "-" + day] = {};
          if (events[month + "-" + day][hour] === undefined)
            events[month + "-" + day][hour] = [];
          events[month + "-" + day][hour].push(event);
        }
      });

      this.events = events;
    }
  },
  mounted() {
    this.$refs.top.scrollIntoView({ behavior: "smooth" });
    axios
      .get(process.env.VUE_APP_API_URL + "/public/events/upcoming")
      .then(response => {
        this.prepareEventData(response.data);
      });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
