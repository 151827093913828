<template>
  <div class="filter">
    <div class="category">
      <label for="category">Kategorie</label>
      <select id="category" name="category" v-model="category">
        <option value="">Kategorie</option>
        <option
          v-for="category in categories"
          :value="category"
          :key="category"
          :selected="category === categoryValue"
        >
          {{ category }}
        </option>
      </select>
    </div>
    <div class="bath">
      <label for="bath">Ort</label>
      <select id="bath" name="bath" v-model="bath">
        <option value="" selected>Ort</option>
        <option v-for="bath in baths" :value="bath" :key="bath">
          {{ bath }}
        </option>
      </select>
    </div>
    <div class="freeCourses">
      <input
        type="checkbox"
        id="free"
        name="free"
        value="true"
        v-model="freeCourses"
      />
      <label for="free">Freie Kurse anzeigen</label>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseFilter",
  props: ["courses", "baths", "categories"],
  data() {
    return {
      freeCoursesValue: false,
      bathValue: "",
      categoryValue: ""
    };
  },
  computed: {
    bath: {
      set: function(value) {
        this.bathValue = value;
        localStorage.setItem("filterBath", value);
        this.$emit("update:bath", value);
      },
      get: function() {
        return this.bathValue;
      }
    },
    category: {
      set: function(value) {
        this.categoryValue = value;
        localStorage.setItem("filterCategory", value);
        this.$emit("update:category", value);
      },
      get: function() {
        return this.categoryValue;
      }
    },
    freeCourses: {
      set: function(value) {
        this.freeCoursesValue = value;
        localStorage.setItem("filterFreeCourses", value);
        this.$emit("update:freeCourses", value);
      },
      get: function() {
        return this.freeCoursesValue;
      }
    }
  },
  created() {
    localStorage.setItem("filterBath", "");
    const filterBath = localStorage.getItem("filterBath");
    if (this.bath === "" && filterBath !== null) this.bath = filterBath;

    if (this.$route.params.filterCategory === undefined) {
      const filterCategory = localStorage.getItem("filterCategory");
      if (this.category === "" && filterCategory !== null)
        this.category = filterCategory;
    }

    const filterFreeCourses = localStorage.getItem("filterFreeCourses");
    if (this.freeCourses === false && filterFreeCourses !== null)
      this.freeCourses = filterFreeCourses;
  },
  watch: {
    courses: function() {
      if (this.$route.params.filterCategory !== undefined) {
        this.category = this.$route.params.filterCategory;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.filter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-template-areas: "category bath free";

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0;
    grid-template-areas:
      "category"
      "bath"
      "free";
  }

  .category {
    grid-area: category;

    select {
      width: 18rem;
    }
  }

  .bath {
    grid-area: bath;
    select {
      width: 18rem;
    }
  }

  .freeCourses {
    grid-area: free;
    display: flex;
    align-items: center;

    input {
      width: 2rem;
      height: 2rem;
      margin: 0;
      margin-right: 0.5rem;
    }
  }
}
</style>
