import Vue from "vue";
import Router from "vue-router";
import CourseList from "@/views/CourseList";
import Booking from "@/views/Booking";
import WaitingList from "@/views/WaitingList";
import WaitingListEdit from "@/views/WaitingListEdit";
import CourseOverview from "@/views/CourseOverview";
import WaitingListCancel from "@/views/WaitingListCancel";
import WaitingListDelete from "@/views/WaitingListDelete";
import LastCallCourseList from "@/views/LastCallCourseList.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/:alias/warteliste",
      name: "waitingList",
      component: WaitingList,
      props: true
    },
    {
      path: "/:alias/buchung",
      name: "booking",
      component: Booking,
      props: true
    },
    {
      path: "/:alias/buchung/:waitingListToken",
      name: "bookingClub",
      component: Booking,
      props: true
    },
    {
      path: "/:alias/warteliste/:token/cancel",
      name: "waitingListCancel",
      component: WaitingListCancel,
      props: true
    },
    {
      path: "/:alias/warteliste/:token/delete",
      name: "WaitingListDelete",
      component: WaitingListDelete,
      props: true
    },
    {
      path: "/:alias/warteliste/:waitingListToken",
      name: "waitingListEdit",
      component: WaitingListEdit,
      props: true
    },
    {
      path: "/kursuebersicht",
      name: "courseOverview",
      component: CourseOverview,
      props: true
    },
    {
      path: "/:alias/last-call/:filterCategory?",
      name: "lastCallCourseList",
      component: LastCallCourseList
    },
    {
      path: "/:alias/kursliste/:filterCategory?",
      name: "courseList",
      component: CourseList
    },
    {
      path: "/:alias/kursliste/:filterCategory/:filterName?",
      name: "courseList",
      component: CourseList
    },
    {
      path: "/:alias/:filterCategory?",
      name: "start"
    }
  ]
});
