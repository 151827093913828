<template>
  <div class="booking" ref="top">
    <div v-if="courseId === null">
      <div class="callout alert">
        Ihrem Wartelisteneintrag ist kein Kurs zugeordnet. Eventuell wurde er
        bereits abgelehnt bzw. nach drei Tagen wird der Eintrag automatisch
        abgelehnt.
      </div>
    </div>
    <div v-else>
      <div class="introduction">
        <h2>{{ course.title }}</h2>
        <div class="details">
          <div class="place">Schwimmbad: {{ course.location }}</div>
          <div class="instructor">Trainer: {{ course.instructor }}</div>
          <div class="course">Kurs-ID: {{ course.id }}</div>
          <div class="price">
            Preis: {{ course.price | formatCurrency
            }}<template v-if="course.type === 'club'"> pro Monat</template>
          </div>
          <template
            v-if="
              settings.enableSingleBookings === true && course.type === 'club'
            "
          >
            <div class="price">
              Preis: {{ course.priceUnit | formatCurrency
              }}<template v-if="course.type === 'club'">
                pro Einzeltermin</template
              >
            </div>
          </template>
        </div>
        <div class="requirements">
          <div class="title">Voraussetzungen</div>
          <div class="description" v-html="course.requirement"></div>
        </div>

        <div class="events">
          <template v-if="event === 0">
            <div class="title">Termine</div>
            <ul>
              <li v-for="event of getEventsFromCourse()" :key="event.date">
                {{ event.dow | formatDateWeekday }} {{ event.date }}
                {{ event.time }} Uhr
              </li>
            </ul>
            <p v-if="course.type === 'club'">
              HINWEIS: Der Club ist ein fortlaufendes Angebot. Die
              Mitgliedschaft endet mit einer schriftlichen Kündigung. Die
              Kündigungsfrist beträgt 2 Monate. Die aufgeführten Termine sind
              nur ein Auszug.
            </p>
          </template>
          <template v-else>
            <div class="title">Termin</div>
            <ul>
              <li>
                {{ eventDate.dow | formatDateWeekday }} {{ eventDate.date }}
                {{ eventDate.time }} Uhr
              </li>
            </ul>
            <p>Die Buchung ist nur für den einzelnen Termin gültig.</p>
          </template>
        </div>
      </div>
      <form :action="settings.linkThankYouPage" method="post">
        <input type="hidden" name="category" :value="course.title" />
        <input type="hidden" name="bath" :value="course.location" />
        <input type="hidden" name="amount" :value="course.price" />
        <input type="submit" ref="thankYou" style="display: none" />
      </form>

      <div
        class="singleBooking"
        v-if="settings.enableSingleBookings === true && course.type === 'club'"
      >
        <label for="event">Kompletten Kurs oder Einzeltermin buchen</label>
        <select v-model="event" name="event">
          <option :value="0"
            >Kompletten Club buchen ({{ course.price | formatCurrency }} pro
            Monat)</option
          >
          <option
            :value="event.id"
            v-for="event in availableEventsForSingleBookings"
            :key="event.id"
            >{{ event.dow | formatDateWeekday }} {{ event.date }} -
            {{ event.time }} ({{ course.priceUnit | formatCurrency }} pro
            Einzeltermin)</option
          >
        </select>
      </div>

      <div class="form">
        <div class="stepper">
          <div class="header" ref="stepperHeader">
            <StepperHeader
              name="Persönliche Daten"
              number="1"
              :step="step"
            ></StepperHeader>
            <div class="divider"></div>
            <StepperHeader
              name="Teilnehmer"
              number="2"
              :step="step"
            ></StepperHeader>
            <div class="divider"></div>
            <StepperHeader
              name="Zahlweise"
              number="3"
              :step="step"
            ></StepperHeader>
            <div class="divider"></div>
            <StepperHeader
              name="Zusammenfassung"
              number="4"
              :step="step"
            ></StepperHeader>
          </div>
          <div class="content" ref="contentTop">
            <div class="step" v-if="step === 1">
              <Customer
                :customer.sync="booking.customer"
                :settings="settings"
                ref="booking-customer"
              ></Customer>
            </div>
            <div class="step" v-else-if="step === 2">
              <Participant
                :participant.sync="booking.participant"
                ref="booking-participant"
              ></Participant>
            </div>
            <div class="step" v-else-if="step === 3">
              <BookingPayment
                :payment.sync="booking.payment"
                :course="course"
                :settings="settings"
                :event="event"
                ref="booking-payment"
              ></BookingPayment>
            </div>
            <div class="step" v-else-if="step === 4">
              <BookingSummary
                v-on:changeStep="changeStep"
                :booking="booking"
                :course="course"
                :event="event"
                ref="booking-summary"
              ></BookingSummary>
            </div>
            <div class="stepp" v-else-if="step === 5">
              <div>
                <h3>
                  Vielen Dank für Ihre Buchung. Eine Buchungsbestätigung wurde
                  Ihnen zugesandt.
                </h3>
                <!--<div v-if="course.type !== 'club' || course.type === 'club' && event !== 0">
                 <div v-if="paymentAccepted === null">
                   <p>Sie haben jetzt die Möglichkeit Ihre Buchung zu bezahlen.</p>
                   <div id="paypalButton"></div>
                 </div>
                 <div v-else-if="paymentAccepted === true">
                   <div class="callout success">
                     <h5>Zahlung erfolgreich</h5>
                     <p>Vielen Dank für Ihre Zahlung. Eine Rechnung/Quittung wurde Ihnen zugesandt.</p>
                   </div>
                 </div>
                 <div v-else-if="paymentAccepted === false">
                   <div class="callout alert">
                     <h5>Zahlung abgebrochen/fehlerhaft</h5>
                     <p>Bei Ihrer Zahlung ist ein Fehler aufgetreten. Ihre Buchung ist gesichert. Wir setzen uns mit Ihnen in Verbindung.</p>
                   </div>
                   <div id="paypalButton"></div>
                 </div>
               </div>-->
                <p>
                  Bitte fügen Sie unsere E-Mailadresse {{ settings.email }} in
                  Ihr Adressbuch hinzu. So gewährleisten Sie einen Zugang aller
                  relevanten Mails.
                </p>
                <p>
                  Eine Buchungsbestätigung erhalten Sie umgehend. Sollten Sie
                  nicht innerhalb von 30 Minuten die Bestätigung der Buchung
                  erhalten haben, kontaktieren Sie uns.
                </p>
                <p>Wir versenden</p>
                <ul>
                  <li>Buchungsbestätigung</li>
                  <li>
                    Infomail (5 Tage vor Kursbeginn bzw. Zum 15. des Monats für
                    Clubs)
                  </li>
                  <li>
                    Zahlungsstatus (bei Nachfrage)/ Zahlungs-Erinnerung (nach 7
                    Tagen)
                  </li>
                  <li>
                    Infoschreiben (Informationen bzw. Meldungen zum aktuellen
                    Betrieb)
                  </li>
                </ul>
                <br />
                <a :href="settings.homepage" target="_blank">{{
                  settings.company
                }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button
            class="button"
            @click="relocateToCourseList"
            v-if="step <= 1 || step >= 5"
          >
            Zurück zur Übersicht
          </button>
          <button class="button" @click="previous" v-else>Zurück</button>
          <button class="button" @click="next" v-if="step < 4">Weiter</button>
          <button class="button" @click="bookCourse()" v-if="step === 4">
            Kostenpflichtig buchen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepperHeader from "@/components/StepperHeader";
import Customer from "@/components/Customer";
import Participant from "@/components/Participant";
import BookingPayment from "@/components/BookingPayment";
import BookingSummary from "@/components/BookingSummary";
import axios from "axios";
import { loadScript } from "@paypal/paypal-js";

export default {
  name: "Booking",
  props: ["courseId", "waitingListToken", "settings"],
  components: {
    BookingSummary,
    BookingPayment,
    StepperHeader,
    Customer,
    Participant
  },
  data() {
    return {
      step: 1,
      booking: {
        customer: {},
        participant: {},
        payment: {}
      },
      bookingResponse: {
        id: 123456,
        price: 12.4
      },
      course: {},
      event: 0,
      paymentAccepted: null
    };
  },
  computed: {
    availableEventsForSingleBookings() {
      const events = this.getEventsFromCourse();
      if (events === undefined) return [];
      return events.slice(0, 2);
    },
    eventDate() {
      return this.course.events.find(item => item.id === this.event);
    }
  },
  methods: {
    bookCourse() {
      this.loadCourseData();
      const vue = this;
      let price = this.course.price;
      if (this.event !== 0) {
        price = this.course.priceUnit;
      }
      let defaultItem = {
        course: this.course.id,
        price: price,
        waitingListToken: this.waitingListToken,
        event: this.event
      };
      if (this.course.type === "club" && this.event === 0) {
        const now = new Date();
        const startDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        defaultItem.clubStartDate = startDate
          .toLocaleDateString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
          })
          .split(".")
          .reverse()
          .join("-");
      }
      const item = Object.assign(defaultItem, this.booking);
      axios
        .post(
          process.env.VUE_APP_API_URL +
            "/public/booking/" +
            this.axios.defaults.headers["X-LOCATION"],
          item
        )
        .then(response => {
          this.bookingResponse = response.data;
          if (vue.settings.linkThankYouPage !== "") {
            this.$refs.thankYou.click();
          }
          vue.changeStep(5);
          //this.loadPaypalIntegration()
        });
    },
    next() {
      this.loadCourseData();
      if (this.step === 1 && this.$refs["booking-customer"].checkForm()) {
        this.step++;
      } else if (
        this.step === 2 &&
        this.$refs["booking-participant"].checkForm()
      ) {
        this.step++;
      } else if (this.step === 3 && this.$refs["booking-payment"].checkForm()) {
        this.step++;
      }
      this.changeStep(this.step);
    },
    previous() {
      this.loadCourseData();
      this.step--;
      this.changeStep(this.step);
    },
    changeStep(step) {
      this.step = step;
      this.$refs.stepperHeader.scrollIntoView({ behavior: "smooth" });
    },
    getEventsFromCourse() {
      if (this.course.type === "club") {
        const startDate = new Date(this.course.startDate);
        let events = [];
        this.course.events.forEach(item => {
          const itemDate = new Date(
            item.date
              .split(".")
              .reverse()
              .join("-")
          );
          if (itemDate >= startDate) events.push(item);
        });
        return events;
      }

      return this.course.events;
    },
    relocateToCourseList() {
      this.$router.push({ name: "courseList" });
    },
    loadCourseData() {
      axios
        .get(process.env.VUE_APP_API_URL + "/public/courses/" + this.courseId)
        .then(response => {
          this.course = response.data;
          if (
            this.waitingListToken === undefined &&
            this.course.capacity - this.course.workload <= 0
          ) {
            alert("Der Kurs wurde zwischenzeitlich komplett ausgebucht.");
            this.relocateToCourseList();
          }
        });
    },
    loadPaypalIntegration() {
      if (
        this.settings.paypalApiKey === "" ||
        (this.course.type === "club" && this.event === 0)
      )
        return;
      const vue = this;
      const itemDescription =
        this.event === 0
          ? ""
          : "Einzelbuchung für den " +
            this.eventDate.date +
            " um " +
            this.eventDate.time +
            " Uhr";
      loadScript({
        "client-id": this.settings.paypalApiKey,
        currency: "EUR",
        debug: true
      }).then(paypal => {
        this.paypal = paypal;
        this.paypal
          .Buttons({
            createOrder: function(data, actions) {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: "EUR",
                      value: vue.bookingResponse.price,
                      breakdown: {
                        item_total: {
                          currency_code: "EUR",
                          value: vue.bookingResponse.price
                        }
                      }
                    },
                    items: [
                      {
                        name: vue.course.title + " (ID: " + vue.course.id + ")",
                        quantity: 1,
                        category: "DIGITAL_GOODS",
                        unit_amount: {
                          currency_code: "EUR",
                          value: vue.bookingResponse.price
                        },
                        description: itemDescription
                      }
                    ],
                    invoice_id: vue.bookingResponse.id
                  }
                ],
                payer: {
                  email_address: vue.booking.customer.email_address,
                  name: {
                    surname: vue.booking.customer.firstName,
                    given_name: vue.booking.customer.lastName
                  }
                }
              });
            },
            onApprove: function(data, actions) {
              return actions.order.capture().then(function(details) {
                vue.paymentAccepted = true;

                axios.post(
                  process.env.VUE_APP_API_URL +
                    "/public/booking/" +
                    vue.bookingResponse.token +
                    "/payment/paypal",
                  {
                    paypal: details
                  }
                );
              });
            },
            onError: function() {
              vue.paymentAccepted = false;
            }
          })
          .render("#paypalButton");
      });
    }
  },
  created() {
    if (this.waitingListToken !== undefined) {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/public/waitinglist/" +
            this.waitingListToken
        )
        .then(response => {
          this.courseId = response.data.course;
          this.booking.customer = response.data.customer;
          this.booking.participant = response.data.participant;
          this.loadCourseData();
        })
        .catch(() => {
          this.relocateToCourseList();
        });
    } else {
      if (this.courseId === undefined) this.relocateToCourseList();
      else if (undefined === this.course) this.relocateToCourseList();
      else this.loadCourseData();
    }

    axios
      .get(
        process.env.VUE_APP_API_URL +
          "/public/location/" +
          this.axios.defaults.headers["X-LOCATION"] +
          "/settings"
      )
      .then(response => {
        this.settings = response.data;
      });
  },
  mounted() {
    this.$refs.top.scrollIntoView({ behavior: "smooth" });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.requirements .title,
.events .title {
  font-size: 2rem;
  color: #f18904;
  font-weight: 600;
}

.introduction {
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-areas:
    "title title"
    "details events"
    "requirements events";

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "title"
      "details"
      "requirements"
      "events";
  }

  h2 {
    grid-area: title;
    @media (max-width: 640px) {
      font-size: 1.5rem;
      margin: 0;
    }
  }

  .details {
    grid-area: details;
  }

  .requirements {
    grid-area: requirements;

    .title {
      @media (max-width: 640px) {
        font-size: 1.25rem;
      }
    }
  }

  .events {
    grid-area: events;

    .title {
      @media (max-width: 640px) {
        font-size: 1.25rem;
      }
    }

    ul {
      @media (max-width: 640px) {
        padding-left: 1rem;
      }
    }
  }
}

.form {
  .stepper {
    .header {
      align-items: stretch;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 2rem 0;

      .step {
        padding: 1rem 0;
        text-align: center;
        flex-grow: 1;
        font-size: 1.2rem;

        @media (max-width: 640px) {
          font-size: 1rem;
        }

        &.completed {
          opacity: 0.2;
        }

        &.active {
          font-weight: 600;

          .badge {
            background: #f18904;
            color: #fff;
          }
        }
      }

      .divider {
        flex: 1 1 0;
        max-width: 100%;
        height: 0;
        max-height: 0;
        border: solid;
        transition: inherit;
        align-self: center;
        border-width: thin 0 0;
        border-color: rgba(0, 0, 0, 0.12);

        @media (max-width: 640px) {
          display: none;
        }
      }
    }
  }
}
</style>
