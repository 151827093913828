<template>
  <div class="preferences">
    <div class="callout alert" v-if="errors.length > 0">
      Bitte korrigieren Sie die rot markierten Felder.
    </div>
    <div class="bath">
      <h3>Bad</h3>
      <div v-for="(bath, index) in baths" :key="index" class="widget">
        <input
          type="checkbox"
          :id="'bath-' + index"
          name="bath"
          v-model="preferenceProxy.bath"
          :value="bath"
        />
        <label :for="'bath-' + index" :class="checkError('bath')">{{
          bath
        }}</label>
      </div>
    </div>
    <div class="category">
      <h3>Kategorie</h3>
      <div v-for="(category, index) in categories" :key="index" class="widget">
        <input
          type="checkbox"
          :id="'category-' + index"
          name="category"
          v-model="preferenceProxy.category"
          :value="category"
        />
        <label :for="'category-' + index" :class="checkError('category')">{{
          category
        }}</label>
      </div>
    </div>
    <div class="availability">
      <h3>Zeitliche Verfügbarkeit</h3>
      <div class="callout alert" v-if="!timeslotSelected && errors.length > 0">
        Bitte wählen Sie an mindestens einem Tag eine Start- und Endzeit aus.
      </div>
      <div class="weekdays">
        <div v-for="(dow, index) in weekdays" :key="index" class="weekday">
          <h4>{{ dow }}</h4>
          <div class="timeslot">
            <label :for="'start-dow-' + index" :class="checkError('dow')"
              >Von</label
            >
            <select
              :for="'start-dow-' + index"
              v-model="preferenceProxy.times[index]['start']"
            >
              <option>--</option>
              <option v-for="timeslot in getTimeSlots()" :key="timeslot">{{
                timeslot
              }}</option>
            </select>

            <label :for="'end-dow-' + index" :class="checkError('dow')"
              >Bis</label
            >
            <select
              :for="'end-dow-' + index"
              v-model="preferenceProxy.times[index]['end']"
            >
              <option>--</option>
              <template v-for="timeslot in getTimeSlots()">
                <option
                  v-if="timeslot > preferenceProxy.times[index]['start']"
                  :key="timeslot"
                  >{{ timeslot }}
                </option>
              </template>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Preferences",
  props: ["baths", "categories", "preferences"],
  data() {
    return {
      preferenceValue: {},
      errors: [],
      weekdays: [
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
        "Sonntag"
      ]
    };
  },
  computed: {
    preferenceProxy: {
      set: function(value) {
        this.preferenceValue = value;
        this.$emit("update:preferences", this.preferenceValue);
      },
      get: function() {
        return this.preferences;
      }
    },
    timeslotSelected() {
      let isTimeslotSelected = false;
      if (this.preferenceProxy.times !== undefined) {
        this.preferenceProxy.times.forEach(day => {
          if (day["start"] !== "" && day["end"] !== "")
            isTimeslotSelected = true;
        });
      }
      return isTimeslotSelected;
    }
  },
  methods: {
    checkForm() {
      let errors = [];
      if (
        this.preferenceProxy.bath === undefined ||
        this.preferenceProxy.bath.length === 0
      )
        errors.push("bath");
      if (
        this.preferenceProxy.category === undefined ||
        this.preferenceProxy.category.length === 0
      )
        errors.push("category");
      if (this.timeslotSelected === false) errors.push("timeslot");
      else {
        this.preferenceProxy.times.forEach(day => {
          if (
            (day["start"] !== "--" &&
              day["start"] !== "" &&
              day["end"] === "--") ||
            (day["end"] !== "--" && day["end"] !== "" && day["start"] === "--")
          )
            errors.push("timeslot");
        });
      }

      this.errors = errors;
      return this.errors.length === 0;
    },
    checkError(field) {
      return this.errors.indexOf(field) !== -1 ? "error" : "";
    },
    getTimeSlots() {
      let timeslots = [];
      const minutes = ["00", "15", "30", "45"];
      for (let hour = 8; hour < 22; hour++) {
        minutes.forEach(minute => {
          if (hour < 10) timeslots.push("0" + hour + ":" + minute);
          else timeslots.push(hour + ":" + minute);
        });
      }
      return timeslots;
    }
  },
  created() {
    this.preferenceValue = this.preferences;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.preferences {
  display: grid;
  grid-row-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "bath category"
    "availability availability";

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "bath"
      "category"
      "availability";
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  h4 {
    margin: 0;
  }

  .widget {
    display: flex;
    margin-bottom: 1rem;
  }

  .bath {
    grid-area: bath;
  }

  .category {
    grid-area: category;
  }

  .availability {
    grid-area: availability;

    .weekdays {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-column-gap: 1rem;

      @media (max-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .timeslot {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
    }
  }
}
</style>
