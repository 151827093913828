<template>
  <div class="waiting-list-delete">
    <h2>Wartelisteneintrag löschen</h2>
    <div class="callout success" v-if="status === true">
      Vielen Dank für Ihre Rückmeldung.
    </div>
    <div class="callout alert" v-if="status === false">
      Leider gab es ein Problem beim Verarbeiten Ihrer Anfrage. Eventuell wurde
      der Wartelisteneintrag bereits gelöscht?
    </div>
    <div class="buttons">
      <button class="button" @click="relocateToCourseList">
        Zurück zur Übersicht
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "WaitingListDelete",
  components: {},
  props: ["token"],
  data() {
    return {
      status: null
    };
  },
  computed: {},
  methods: {
    relocateToCourseList() {
      this.$router.push({ name: "courseList" });
    }
  },
  created() {
    axios
      .delete(process.env.VUE_APP_API_URL + "/public/waitinglist/" + this.token)
      .then(() => {
        this.status = true;
      })
      .catch(() => {
        this.status = false;
      });
  }
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 1.5rem;
}
.buttons {
  .button {
    width: auto;
  }
}
</style>
