<template>
  <div>
    <div class="grid-container">
      <div class="grid-x grid-margin-x grid-margin-y">
        <div class="cell small-12 medium-8 large-6">
          <table class="unstriped">
            <thead>
            <tr>
              <td colspan="2">
                <div class="flex-container align-justify table-header align-middle">
                  <span class="h3">Ihre Daten</span>
                  <button class="button" @click="changeStep(2)">Bearbeiten</button>
                </div>
              </td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Geschlecht</td>
              <td>{{ getSalutation(customer.gender) }}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{ customer.firstname }} {{ customer.lastname }}</td>
            </tr>
            <tr>
              <td>Adresse</td>
              <td>{{ customer.street }} {{ customer.streetNumber }}<br>{{ customer.postal }} {{ customer.city }}</td>
            </tr>
            <tr>
              <td>E-Mail</td>
              <td>{{ customer.email }}</td>
            </tr>
            <tr>
              <td>Telefon</td>
              <td>{{ customer.phone }}</td>
            </tr>
            </tbody>
            <thead>
            <tr>
              <td colspan="2">
                <div class="flex-container align-justify table-header align-middle">
                  <span class="h3">Daten des Teilnehmers</span>
                  <button class="button" @click="changeStep(3)">Bearbeiten</button>
                </div>
              </td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Geschlecht</td>
              <td>{{ getSalutation(participant.gender) }}</td>
            </tr>
            <tr>
              <td>Name</td>
              <td>{{ participant.firstname }} {{ participant.lastname }}</td>
            </tr>
            <tr>
              <td>Geburtstag</td>
              <td>{{ participant.dateOfBirth }}</td>
            </tr>
            <tr>
              <td>Weitere Informationen</td>
              <td>{{ participant.moreInformation }}</td>
            </tr>
            </tbody>
            <thead>
            <tr>
              <td colspan="2">
                <div class="flex-container align-justify table-header align-middle">
                  <span class="h3">Präferenzen</span>
                  <button class="button" @click="changeStep(1)">Bearbeiten</button>
                </div>
              </td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Schwimmbad</td>
              <td>{{ preferences.bath.join(', ') }}</td>
            </tr>
            <tr>
              <td>Kategorie</td>
              <td>{{ preferences.category.join(', ') }}</td>
            </tr>
            <tr>
              <td>Zeiten</td>
              <td>
                <ul>
                  <li v-for="time in selectedTimeslots" :key="time">{{ time }}</li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaitingListSummary',
  props: ['customer', 'participant', 'preferences'],
  data () {
    return {
      weekdays: [
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
        'Sonntag'
      ]
    }
  },
  computed: {
    selectedTimeslots() {
      let selectedTimeslots = []
      for (let day = 0; day < 7; day++) {
        if (this.preferences.times !== undefined && this.preferences.times[day].start !== '' && this.preferences.times[day].end !== '') {
          selectedTimeslots.push(this.weekdays[day] + ' ' + this.preferences.times[day].start + ' bis ' + this.preferences.times[day].end)
        }
      }
      return selectedTimeslots
    }

  },
  methods: {
    changeStep(value) {
      this.$emit('changeStep', value)
    },
    getSalutation(value) {
      if (value === 'female') return 'Weiblich'
      if (value === 'male') return 'Männlich'
      return 'Divers'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
