import Vue from "vue";
import router from "./router";
import App from "./App.vue";
import Vuelidate from "vuelidate";
import axios from "axios";
import "./plugins/filter";
import VueAxios from "vue-axios";

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

Vue.use(Vuelidate);

new Vue({
  router,
  render(h) {
    return h(App);
  }
}).$mount("#app");
