<template>
  <div>
    <div class="load" v-if="percent >= 100">
      <div class="load_inner" :style="'width: 100%'"></div>
      <div class="load_text">Keine freien Plätze</div>
    </div>
    <div class="load" v-else-if="percent > 40">
      <div class="load_inner" :style="'width:' + percent + '%'"></div>
      <div class="load_text left" v-if="spaceLeft > 1">
        {{ spaceLeft }} freie Plätze
      </div>
      <div class="load_text left" v-else>{{ spaceLeft }} freier Platz</div>
    </div>
    <div class="load" v-else-if="percent <= 40">
      <div class="load_inner" :style="'width:' + percent + '%'"></div>
      <div class="load_text right text_black" v-if="spaceLeft > 1">
        {{ spaceLeft }} freie Plätze
      </div>
      <div class="load_text right text_black" v-else>
        {{ spaceLeft }} freier Platz
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CourseWorkload",
  props: ["load", "capacity"],
  computed: {
    spaceLeft() {
      return Math.floor(this.capacity - this.load);
    },
    percent() {
      const percent = Math.floor((this.load / this.capacity) * 100);
      if (percent < 100) return percent;
      return 100;
    }
  }
};
</script>

<style scoped lang="scss">
.load {
  height: 3rem;
  background-color: #8bb1c4;
  position: relative;

  .load_inner {
    height: 3rem;
    background-color: #f18904;
  }

  .load_text {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    color: #fff;
    text-align: center;
    line-height: 3rem;
  }
}
</style>
