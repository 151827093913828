import Vue from 'vue';

const weekdays = {
    1: 'Montag',
    2: 'Dienstag',
    3: 'Mittwoch',
    4: 'Donnerstag',
    5: 'Freitag',
    6: 'Samstag',
    7: 'Sonntag'
}

Vue.filter('formatDateWeekday', function(value) {
    if (value) {
        return weekdays[value]
    }
});

Vue.filter('formatDateTime', function(value) {
    if (value) {
        const date = new Date(value)
        return date.toLocaleDateString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }) + ' ' + date.toLocaleTimeString("de-DE", {
            hour: "2-digit",
            minute: "2-digit",
        });
    }
});

Vue.filter('formatDate', function(value) {
    if (value) {
        const date = new Date(value)
        return date.toLocaleDateString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
    }
});

Vue.filter('formatTime', function(value) {
    if (value) {
        const date = new Date(value)
        return date.toLocaleTimeString("de-DE", {
            hour: "2-digit",
            minute: "2-digit",
        });
    }
});

Vue.filter('formatCurrency', function(value) {
    return parseFloat(value).toFixed(2).replace('.', ',') + ' EUR'
});